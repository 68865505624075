import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://auth.staging.buyco.co",
  realm: "preview",
  clientId: "buyco-app",
});

keycloak.init(
  {
    onLoad: "check-sso",
    checkLoginIframe: false,
  },
  (authenticated) => {
    if (!authenticated) {
      keycloak.login();
    }
  }
);

export default keycloak;