import { Flex } from '@chakra-ui/react'
import React from 'react'
import ToolBox from './components/ToolBox'
import Content from './components/Content'
import { listenPayload, setJwt } from './api'
import { AppContext } from './contexts';
import { Shipment } from './interfaces'
import { ShipmentAction } from './actions'

type Props = {}

export default function App({ }: Props) {
  const { actions, keycloak } = React.useContext(AppContext);

  console.log({ keycloak })

  const onPayloadReceived = (shipments: Shipment[]) => {
    console.log('Fire', { shipments })
    actions.dispatch.shipments({
      type: ShipmentAction.ADD,
      payload: shipments
    });
    alert('Payload has been loaded!')
  }

  React.useEffect(() => {
    setJwt();
    listenPayload(onPayloadReceived);
  }, []);

  return (
    <Flex>
      <ToolBox />
      <Content />
    </Flex>
  )
}